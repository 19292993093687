
<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { useForm, useField } from 'vee-validate'
import ChevronRightIcon from '@heroicons/vue/20/solid/ChevronRightIcon'
import type { NewLeadPayload } from '~/types'
import { Category } from '~/types'
import Button from '~/components/UI/Button.vue'
import InputText from '~/components/UI/InputText.vue'
import CitySelectField from '~/components/UI/CitySelectField.vue'
import ProvinceSelectField from '~/components/UI/ProvinceSelectField.vue'
import Privacy from '~/components/Privacy.vue'

const { handleSubmit, meta } = useForm()
const { getBrandById } = useBrandStore()
const { selectedRate } = storeToRefs(useRateStore())
const { clearSelectedRate } = useRateStore()
const { city, province } = storeToRefs(useFilterStore())
const { setCity, setProvince } = useFilterStore()
const { createNewLead } = useLead()
const { loading } = storeToRefs(useUIStore())
const { email: storeEmail, phone: storePhone } = storeToRefs(useUserStore())
const { setEmail, setPhone } = useUserStore()

const isSubmitted = ref(false)

const isOpen = computed(() => !!selectedRate.value)

const isFormValid = computed(() => meta.value.valid);

const { value: name, meta: nameMeta, errorMessage: nameError } = useField<string>('name', { requiredRule })
const { value: surname, meta: surnameMeta, errorMessage: surnameError } = useField<string>('surname', { requiredRule })
const { value: email, meta: emailMeta, errorMessage: emailError } = useField<string>('email', { requiredRule, emailRule })
const { value: phone, meta: phoneMeta, errorMessage: phoneError } = useField<string>('phone', { requiredRule, italianPhoneRule })
const { value: privacy1, meta: privacy1Meta, errorMessage: privacy1Error } = useField<boolean>('privacy1', { requiredRule })
const { value: privacy2 } = useField<boolean>('privacy2')
const { value: privacy3 } = useField<boolean>('privacy3')
const { value: privacy4 } = useField<boolean>('privacy4')

const topic = computed(() => {
  if (selectedRate.value?.category === Category.ENERGY) {
    return 'di luce e gas'
  } else if (selectedRate.value?.category === Category.INSURANCE) {
    return 'di assicurazioni'
  } else if (selectedRate.value?.category === Category.TELCO) {
    return 'di telefonia'
  }
})

const onCity = (value: any) => {
  setCity(value.nome)
  setProvince(value.provincia.nome)
}

const onProvince = (value: string) => {
  setCity('')
  setProvince(value)
}

const togglePrivacyAll = (value: boolean) => {
  togglePrivacy1(value)
  togglePrivacy2(value)
  togglePrivacy3(value)
  togglePrivacy4(value)
}

const togglePrivacy1 = (value: boolean) => {
  privacy1.value = value
}

const togglePrivacy2 = (value: boolean) => {
  privacy2.value = value
}

const togglePrivacy3 = (value: boolean) => {
  privacy3.value = value
}

const togglePrivacy4 = (value: boolean) => {
  privacy4.value = value
}

const showNameError = computed(() =>
  (isSubmitted.value || nameMeta.touched) && !nameMeta.valid
)

const showSurameError = computed(() =>
  (isSubmitted.value || surnameMeta.touched) && !surnameMeta.valid
)

const showEmailError = computed(() =>
  (isSubmitted.value || emailMeta.touched) && !emailMeta.valid
)

const showPhoneError = computed(() =>
  (isSubmitted.value || phoneMeta.touched) && !phoneMeta.valid
)

const showPrivacy1Error = computed(() =>
  (isSubmitted.value || privacy1Meta.touched) && !privacy1Meta.valid
)

const maybeSubmit = () => {
  isSubmitted.value = true
  submit()
}

const submit = handleSubmit(async () => {
  const payload: NewLeadPayload = {
    category: selectedRate.value?.category,
    email: email.value,
    phone: phone.value,
    privacy1: privacy1.value,
    privacy2: privacy2.value,
    privacy3: privacy3.value,
    privacy4: privacy4.value,
    rateName: selectedRate.value?.name || '-',
    brandName: getBrandById(selectedRate.value?.brandId)?.name || '-',
    name: name.value,
    surname: surname.value,
    city: city.value,
    province: province.value,
  }
  await createNewLead(payload)
  clearSelectedRate()
})

watch(() => storeEmail.value, (value) => {
  if (value === email.value) {
    return
  }
  email.value = value
}, { immediate: true })

watch(() => storePhone.value, (value) => {
  if (value === phone.value) {
    return
  }
  phone.value = value
}, { immediate: true })

const onEmailInput = (value: string) => {
  if (!emailMeta.valid) {
    return
  }

  setEmail(value)
}

const onPhoneInput = (value: string) => {
  if (!phoneMeta.valid) {
    return
  }

  setPhone(value)
}
</script>

<template>
  <form class="flex justify-content-center" @submit="handleSubmit(maybeSubmit)">
    <Dialog v-on-click-outside="clearSelectedRate" :visible="isOpen" @hide="clearSelectedRate" modal closable
      close-on-escape :draggable="false" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      @update:visible="clearSelectedRate" :pt="{
        'icons': { class: 'self-start' },
      }">
      <template #header>
        <div class="w-full flex flex-col items-center justify-center gap-2">
          <span class="text-2xl sm:text-4xl text-slate-600 text-center font-bold white-space-nowrap">
            {{ selectedRate?.name }}
          </span>
          <span class="text-lg sm:text-2xl text-primary text-center white-space-nowrap">
            Parla gratis e <span class="font-bold">senza impegno</span> con un esperto {{ topic }}
          </span>
        </div>
      </template>
      <div class="flex flex-col gap-6 max-h-[60vh] overflow-y-auto	">
        <div class="flex flex-wrap gap-4">
          <InputText id="rate-dialog-name" class="w-full md:w-[calc(50%-0.5rem)]" v-model="name" type="text"
            placeholder="Nome" :has-error="showNameError" autofocus>
            <template #label> Nome<span class="text-xs">*</span></template>
            <template #error>{{ nameError }}</template>
          </InputText>
          <InputText id="rate-dialog-surname" class="w-full sm:w-[calc(50%-0.5rem)]" v-model="surname" type="text"
            placeholder="Cognome" :has-error="showSurameError">
            <template #label> Cognome<span class="text-xs">*</span></template>
            <template #error>{{ surnameError }}</template>
          </InputText>
          <InputText id="rate-dialog-email" class="w-full sm:w-[calc(50%-0.5rem)]" v-model="email" type="email"
            placeholder="Email" :has-error="showEmailError" @input="onEmailInput">
            <template #label> Email<span class="text-xs">*</span></template>
            <template #error>{{ emailError }}</template>
          </InputText>
          <InputText id="rate-dialog-phone" class="w-full sm:w-[calc(50%-0.5rem)]" v-model="phone" type="tel"
            placeholder="Telefono" :has-error="showPhoneError" @input="onPhoneInput">
            <template #label> Telefono<span class="text-xs">*</span></template>
            <template #error>{{ phoneError }}</template>
          </InputText>
          <CitySelectField id="rate-dialog-city" class="w-full sm:w-[calc(50%-0.5rem)]" :value="city" :query="unref(city)"
            @input="onCity" />
          <ProvinceSelectField id="rate-dialog-province" class="w-full sm:w-[calc(50%-0.5rem)]" :value="province"
            :query="unref(province)" @input="onProvince" />
        </div>
        <div class="w-full flex flex-col gap-4 items-start justify-center">
          <Privacy :privacy1="privacy1" :privacy2="privacy2" :privacy3="privacy3" :privacy4="privacy4"
            :has-error="showPrivacy1Error" :error="privacy1Error" @change:privacy1="togglePrivacy1"
            @change:privacy2="togglePrivacy2" @change:privacy3="togglePrivacy3" @change:privacy4="togglePrivacy4"
            @change:all="togglePrivacyAll" />
        </div>
      </div>
      <template #footer>
        <div class="w-full flex flex-col gap-8 items-center justify-center">
          <Button v-tooltip.top="{
            value: 'Per continuare completa correttamente tutti i campi contrassegnati da asterisco o evidenziati in rosso.',
            disabled: loading || !isSubmitted || isFormValid
          }" class="w-full sm:w-1/3 flex items-center justify-center gap-1" :disabled="isSubmitted && !isFormValid"
            @click="maybeSubmit">
            Invia
            <ChevronRightIcon class="h-6 w-6" />
          </Button>
        </div>
      </template>
    </Dialog>
  </form>
</template>