import { Category, type LeadData, type NewLeadPayload } from '~/types';
import { useEnvVariables } from './useEnvVariables';

export const useLead = () => {
  const {
    assApiToken,
    assChannel,
    engApiToken,
    engChannel,
    tlcApiToken,
    tlcChannel
  } = useEnvVariables()
  const { setLoading } = useUIStore()
  const { isEnergy, isTelco, isInsurance } = storeToRefs(useCategoryStore())
  const toast = useSharedToast()

  const categoryAuthToken = computed<string | void>(() => {
    if (isEnergy.value) {
      return engApiToken
    } else if (isTelco.value) {
      return tlcApiToken
    } else if (isInsurance.value) {
      return assApiToken
    }
  })

  const categoryChannel = computed(() => {
    if (isEnergy.value) {
      return engChannel
    } else if (isTelco.value) {
      return tlcChannel
    } else if (isInsurance.value) {
      return assChannel
    }
  })

  const formatDate = (date: Date) => {
    const pad = (num: number) => (num < 10 ? '0' + num : num);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ` +
      `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  };

  /**
   * Fetches the user's IP address from the server.
   * @returns {Promise<string>} A promise that resolves to the user's IP address, or an empty string if not found.
   */
  const userIPAddress = async (): Promise<string> => {
    try {
      const response = await fetch('/api/ip');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      return (typeof data === 'object' && 'ip' in data) ? data.ip : ''
    } catch (error) {
      console.error('Error fetching user IP address:', error)
      return ''
    }
  }

  const getAuthToken = (value?: string): string => {
    switch (value) {
      case Category.ENERGY:
        return engApiToken;
      case Category.INSURANCE:
        return assApiToken;
      case Category.TELCO:
        return tlcApiToken;
      default:
        throw new Error('Invalid category')
    }
  }

  const getChannel = (value?: string): string => {
    switch (value) {
      case Category.ENERGY:
        return engChannel;
      case Category.INSURANCE:
        return assChannel;
      case Category.TELCO:
        return tlcChannel;
      default:
        throw new Error('Invalid category')
    }
  }

  /**
   * Send lead data to the Eutel API.
   * @param userTelNumber The mobile number.
   * @param userIpAddress The IP address of the user.
   */
  const createNewLead = async (value: NewLeadPayload): Promise<boolean> => {
    const authToken = getAuthToken(value.category) || categoryAuthToken.value
    const channel = getChannel(value.category) || categoryChannel.value

    if (authToken === undefined || channel === undefined) {
      throw new Error('authToken and channel are required')
    }

    const leadDate = new Date();
    const leadExpire = new Date()
    leadExpire.setFullYear(leadDate.getFullYear() + 2);

    const leadData: LeadData = {
      type: 'HOT',
      msisdn: value.phone,
      channel,
      gclid: 'gclid-test-stage',
      campaign_id: '1',
      privacy_1: value.privacy1 ? '1' : '0',
      privacy_2: value.privacy2 ? '1' : '0',
      privacy_3: value.privacy3 ? '1' : '0',
      privacy_4: value.privacy4 ? '1' : '0',
      leadDate: formatDate(leadDate),
      leadExpire: formatDate(leadExpire),
      data: {
        // the following key has a typo but that's how the BE it's built
        addres: await userIPAddress(),
        email: value.email,
        file: {
          nome: value.name,
          cognome: value.surname,
          comune: value.city,
          provincia: value.province,
          nome_offerta: value.rateName,
          nome_brand: value.brandName,
          motivo_contatto: value.leadType,
        }
      }
    }

    try {
      setLoading(true)
      const response = await fetch('/api/leads', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(leadData)
      })

      if (!response.ok) {
        throw new Error('Error while creating new lead')
      }

      toast.add({
        severity: 'success',
        summary: 'Richiesta registrata con successo',
        detail: 'La tua richiesta di contatto è stata registrata con successo. Verrai presto contattato da un esperto.',
        group: 'bc',
        life: 10000,
      })

      return await response.json()
    } catch (error) {
      toast.add({
        severity: 'warn',
        summary: 'Qualcosa è andato storto',
        detail: 'Per favore riprovare a breve dopo aver ricaricato la pagina.',
        group: 'bc',
        life: 10000,
      })
      throw new Error('An error occurred while creating a lead');
    } finally {
      setLoading(false)
    }
  }

  return {
    createNewLead
  }
}